import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupToast = _resolveComponent("PopupToast")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("img", {
      src: $data.hasCoupon ? $data.downloadCompleteImage : $data.downloadImage,
      alt: "쿠폰 이미지",
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.clickCoupon())),
      style: { width: '100%', cursor: 'pointer' }
    }, null, 8 /* PROPS */, ["src"]),
    _createVNode(_component_PopupToast, { isPopup: $data.isPopup }, {
      default: _withCtx(() => [
        _createVNode("p", { innerHTML: $data.popUpMessage }, null, 8 /* PROPS */, ["innerHTML"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["isPopup"])
  ], 64 /* STABLE_FRAGMENT */))
}