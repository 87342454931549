import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _renderSlot(_ctx.$slots, "default", {
      days1: $options.days1,
      days2: $options.days2,
      hours1: $options.hours1,
      hours2: $options.hours2,
      minutes1: $options.minutes1,
      minutes2: $options.minutes2,
      seconds1: $options.seconds1,
      seconds2: $options.seconds2,
      milliseconds1: $options.milliseconds1,
      milliseconds2: $options.milliseconds2
    })
  ]))
}