import { storage, native, device, env } from '@/tools';
import date from '@/common/date';
import api from '@/common/api';
import {
  /* CONST */
  PG_TYPE,
  PAY_METHOD,
  /* TYPE */
  TypePayment,
} from './interface';
import { Payment } from './Payment';

export default class KoreanPayment extends Payment {
  private _isJiayouApp: boolean;

  private _isInApp: boolean;

  private _isMobile: boolean;

  get isJiayouApp(): boolean {
    return this._isJiayouApp;
  }

  get isInApp(): boolean {
    return this._isInApp;
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  constructor(paymentInfo: TypePayment, isJiayouApp: boolean) {
    super(paymentInfo, PG_TYPE.UPLUS);
    this._isJiayouApp = isJiayouApp;
    this._isInApp = !!env.isInApp();
    this._isMobile = !!device.isMobile();
  }

  /**
   * @override
   */
  init(): Promise<void> {
    return new Promise((resolve) => {
      const tag = document.createElement('script');
      const firstScriptTag = document.getElementsByTagName('script')[0];
      tag.id = 'xpay-corssplatform-script';
      tag.src = 'https://xpay.tosspayments.com/xpay/js/xpay_crossplatform.js';
      (firstScriptTag.parentNode as HTMLElement).insertBefore(tag, firstScriptTag);
      tag.addEventListener('load', () => resolve());
    });
  }

  /**
   * @override
   */
  async fillPgForm(): Promise<void> {
    const isPc = !this._isMobile;
    const isAppPay = this._isInApp;
    const isJiayouApp = this._isJiayouApp;
    const { mtIdx, titleForPG, totalPaidMoney, payMethod, months } = this.paymentInfo;

    const form = document.querySelector('#LGD_PAYINFO') as HTMLFormElement;
    const timestamp = form.querySelector('#LGD_TIMESTAMP') as HTMLInputElement;
    const hashData = form.querySelector('#LGD_HASHDATA') as HTMLInputElement;
    const customFirstPay = form.querySelector('#LGD_CUSTOM_FIRSTPAY') as HTMLInputElement;
    const customUsablePay = form.querySelector('#LGD_CUSTOM_USABLEPAY') as HTMLInputElement;
    const provideTerm = form.querySelector('#LGD_PROVIDE_TERM') as HTMLInputElement;
    const closeDate = form.querySelector('#LGD_CLOSEDATE') as HTMLInputElement;
    const casNoteUrl = form.querySelector('#LGD_CASNOTEURL') as HTMLInputElement;
    const noIntInf = form.querySelector('#LGD_NOINTINF') as HTMLInputElement;
    const installRange = form.querySelector('#LGD_INSTALLRANGE') as HTMLInputElement;
    const returnUrl = form.querySelector('#LGD_RETURNURL') as HTMLInputElement;
    const windowType = form.querySelector('#LGD_WINDOW_TYPE') as HTMLInputElement;
    const customSkin = form.querySelector('#LGD_CUSTOM_SKIN') as HTMLInputElement;
    const customSwitchingType = form.querySelector('#LGD_CUSTOM_SWITCHINGTYPE') as HTMLInputElement;
    const kvpmIspAutoAppYn = form.querySelector('#LGD_KVPMISPAUTOAPPYN') as HTMLInputElement;
    const kvpmIspWapUrl = form.querySelector('#LGD_KVPMISPWAPURL') as HTMLInputElement;
    const kvpmIspCancelUrl = form.querySelector('#LGD_KVPMISPCANCELURL') as HTMLInputElement;
    const mpiLotteAppCardWapUrl = form.querySelector('#LGD_MPILOTTEAPPCARDWAPURL') as HTMLInputElement;
    const amount = form.querySelector('#LGD_AMOUNT') as HTMLInputElement;
    const deliveryInfo = form.querySelector('#LGD_DELIVERYINFO') as HTMLInputElement;
    const productInfo = form.querySelector('#LGD_PRODUCTINFO') as HTMLInputElement;
    const buyer = form.querySelector('#LGD_BUYER') as HTMLInputElement;
    const buyerEmail = form.querySelector('#LGD_BUYEREMAIL') as HTMLInputElement;
    const oid = form.querySelector('#LGD_OID') as HTMLInputElement;
    const osTypeCheck = form.querySelector('#LGD_OSTYPE_CHECK') as HTMLInputElement;
    const windowVer = form.querySelector('#LGD_WINDOW_VER') as HTMLInputElement;

    const pgHashData = await this.createPgHashData(timestamp.value);
    const installmentData = this.getInstallmentData();
    const email = storage.persistent.get('ellaEmail') || '';
    const mallReserved = `is_in_app=${isAppPay ? 1 : 0}&is_pc=${isPc ? 1 : 0}&month=${months}&lang=kr${
      payMethod === PAY_METHOD.VIRTUAL_ACCOUNT
        ? `&expireDt=${Date.prototype.formatYYYYMMDDHHmmss.call(date.addDays(new Date(), 2))}`
        : ''
    }`;

    hashData.value = pgHashData.replace(/\s/gi, '');
    amount.value = `${totalPaidMoney}`;
    deliveryInfo.value = mallReserved;
    productInfo.value = titleForPG;
    buyer.value = email.replace(/\s/gi, '');
    buyerEmail.value = email.replace(/\s/gi, '');
    oid.value = mtIdx;

    if (isPc) {
      windowType.value = 'iframe';
      customSwitchingType.value = 'IFRAME';
      customSkin.value = 'red';
      osTypeCheck.value = 'P';
      windowVer.value = '2.5';
    } else {
      windowType.value = 'submit';
      customSwitchingType.value = 'SUBMIT';
      customSkin.value = 'SMART_XPAY2';
      kvpmIspAutoAppYn.value = 'A';
      if (isAppPay && !!native.isIOS()) {
        const iosPayUrlPrefix = isJiayouApp ? 'tutoringcn' : 'tutoring';
        kvpmIspWapUrl.value = `${iosPayUrlPrefix}://charge/pay_result`;
        kvpmIspCancelUrl.value = `${iosPayUrlPrefix}://charge/pay_cancel`;
        mpiLotteAppCardWapUrl.value = `${iosPayUrlPrefix}://charge/pay_result`;
      } else {
        kvpmIspWapUrl.value = '';
        kvpmIspCancelUrl.value = '';
        mpiLotteAppCardWapUrl.value = '';
      }
    }
    if (payMethod === PAY_METHOD.CARD) {
      customFirstPay.value = 'SC0010';
      customUsablePay.value = 'SC0010';
      returnUrl.value = this.getApiUrl('mvc', 'charge_by_uplus');
    } else if (payMethod === PAY_METHOD.PHONE) {
      provideTerm.value = '1';
      customFirstPay.value = 'SC0060';
      customUsablePay.value = 'SC0060';
      returnUrl.value = this.getApiUrl('mvc', 'charge_by_uplus');
    } else if (payMethod === PAY_METHOD.VIRTUAL_ACCOUNT) {
      provideTerm.value = '1';
      customFirstPay.value = 'SC0040';
      customUsablePay.value = 'SC0040';
      returnUrl.value = this.getApiUrl('mvc', 'charge_by_uplus_as_deposit_only_account'); // 가상 계좌 생성 시 처리 url
      casNoteUrl.value = this.getApiUrl('mvc', 'callback_from_pg_by_deposit_only_account'); // lg u+에서 callback하는 url
      closeDate.value = Date.prototype.noSeparatorFormatYYYYMMDDHHmmss.call(date.addDays(new Date(), 2));
    }
    // 무이자 할부 데이터 입력 부분, 추후 복구 예정(22.09.02)
    if (installmentData.noIntInf) noIntInf.value = '';
    if (installmentData.installRange) installRange.value = installmentData.installRange;
  }

  /**
   * @override
   */
  submitPgForm(): void {
    const form = document.querySelector('#LGD_PAYINFO') as HTMLFormElement;
    const cstPlatform = form.querySelector('#CST_PLATFORM') as HTMLInputElement;
    const windowType = form.querySelector('#LGD_WINDOW_TYPE') as HTMLInputElement;
    if (this._isMobile) {
      window.open_paymentwindow(form, cstPlatform.value, windowType.value);
    } else {
      window.openXpay(form, cstPlatform.value, windowType.value, null, '', '');
    }
  }

  async createPgHashData(timestamp: string): Promise<string> {
    try {
      const { mtIdx, totalPaidMoney } = this._paymentInfo;
      const hashData = await api.post('/mvc/make_pg_hash_data', {
        pg: PG_TYPE.UPLUS,
        timestamp,
        amt: `${totalPaidMoney}`,
        oid: mtIdx,
      });
      return hashData as unknown as string;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  getInstallmentData(): { noIntInf: string; installRange: string } {
    const { months: maxMonth } = this.paymentInfo;
    const freeInstallmentMonth = maxMonth > 12 ? 12 : maxMonth;

    let installRange = '';
    let freeInterestList: Array<string> = [];

    if (freeInstallmentMonth > 1) {
      const cardTypeNoList = [11, 21, 31, 32, 33, 41, 51, 61, 71, 91];
      freeInterestList = cardTypeNoList.map((cardTypeNo) => {
        let value = `${cardTypeNo}-`;
        // eslint-disable-next-line no-plusplus
        for (let month = 2; month <= freeInstallmentMonth; month++) {
          value += month;
          if (month < maxMonth) value += ':';
        }
        return value;
      });
    }

    if (maxMonth > 12) {
      installRange += '0:';
      // eslint-disable-next-line no-plusplus
      for (let month = 2; month <= maxMonth; month++) {
        installRange += month;
        if (month < maxMonth) installRange += ':';
      }
    }
    return {
      noIntInf: freeInterestList.join(','),
      installRange,
    };
  }

  getApiUrl(type: string, action: string): string {
    const envId = localStorage.getItem(type === 'mvc' ? 'mvcEnvId' : 'apiEnvId');
    const prefix = envId ? `${envId}-` : '';
    const apiServerUrl = `https://${prefix}api.tutoring.co.kr`;
    return `${apiServerUrl}/${type}/${action}`;
  }
}
