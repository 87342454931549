import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "main_title" }
const _hoisted_2 = { class: "tit_main" }
const _hoisted_3 = { class: "item_g item_merit" }
const _hoisted_4 = { class: "inner_g" }
const _hoisted_5 = { class: "slide_box" }
const _hoisted_6 = { class: "tit_merit" }
const _hoisted_7 = { class: "screen_out" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("h3", _hoisted_2, [
        _createTextVNode(_toDisplayString($props.titleGroup[0]) + " ", 1 /* TEXT */),
        _createVNode("b", null, _toDisplayString($props.titleGroup[1]), 1 /* TEXT */)
      ])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_swiper, {
            class: "tutors-carousel",
            navigation: true,
            pagination: { clickable: true },
            loop: true,
            slidesPerView: 3,
            centeredSlides: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.swiperImg, (img, index) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
                  default: _withCtx(() => [
                    _createVNode("strong", _hoisted_6, [
                      _createVNode("img", {
                        src: img,
                        class: "img_g",
                        alt: $props.swiperCts[index]
                      }, null, 8 /* PROPS */, ["src", "alt"])
                    ]),
                    _createVNode("p", _hoisted_7, _toDisplayString($props.swiperCts[index]), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}