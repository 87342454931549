import { createWebHistory, createRouter } from 'vue-router';
import tools from './common/tools';
import { native } from './tools';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./page/home/Home.vue'),
    beforeEnter: tools.setUtmSessionFromQuerystring,
  },
  {
    path: '/promotions/pr90days',
    name: 'Pr90days',
    component: () => import('./page/promotion/Pr90days.vue'),
  },
  {
    path: '/promotions/pr90dayMiddle',
    name: 'Pr90daysMiddle',
    component: () => import('./page/promotion/Pr90daysMiddle.vue'),
  },
  {
    path: '/promotions/pr0301',
    redirect: '/',
    name: 'Pr0301',
    component: () => import('./page/promotion/Pr0301.vue'),
  },
  {
    path: '/promotions/marketPrice',
    name: 'MarketPrice',
    component: () => import('./page/promotion/MarketPrice.vue'),
  },
  {
    path: '/promotions/reportReward',
    name: 'ReportReward',
    component: () => import('./page/promotion/ReportRewardSystem.vue'),
  },
  {
    path: '/promotions/kbstar',
    name: 'kbstar',
    component: () => import('./page/promotion/KbStar.vue'),
  },
  {
    path: '/pages/guide/lesson',
    name: 'guideLesson',
    component: () => import('./page/PageGuideLesson/PageGuideLesson.vue'),
  },
  {
    path: '/promotions/ReviewEvent',
    name: 'ReviewEvent',
    component: () => import('./page/promotion/ReviewEvent.vue'),
  },
  {
    path: '/promotions/welcome',
    name: 'WelcomePackEvent',
    component: () => import('./page/promotion/WelcomePackEvent.vue'),
  },
  // 수강권 구매
  {
    path: '/order',
    name: 'Order',
    component: () => import('./page/order/Order.vue'),
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('./page/payment/Payment.vue'),
  },
  {
    path: '/pages/events/downloadCoupons',
    name: 'downloadCoupons',
    component: () => import('./page/PageDownloadCoupons/PageDownloadCoupons.vue'),
  },
  // 수강신청__365올패스
  {
    path: '/plan/all',
    name: 'plan',
    component: () => import('./page/plan/planAllPass/PlanAllPass.vue'),
  },
  {
    path: '/plan/all/pass',
    name: 'planAllPass',
    component: () => import('./page/plan/planAllPass/PlanAllApply.vue'),
  },
  // 수강신청__단과
  // {
  //   path: '/plan/single',
  //   name: 'planSingle',
  //   component: () => import('./page/plan/planAllSingle/PlanSingle.vue'),
  // },
  // {
  //   path: '/plan/single/list',
  //   name: 'planSingleList',
  //   component: () => import('./page/plan/planAllSingle/PlanSingleList.vue'),
  // },

  // {
  //   path: '/plan/single/:courseIdx',
  //   component: () => import('./page/plan/planAllSingle/singleCourseIdx.vue'),
  //   name: 'singleCourseIdx',
  // },
  // 레벨테스트
  {
    path: '/appInfo',
    name: 'AppInfo',
    component: () => import('./page/appInfo/appInfo.vue'),
  },
  {
    path: '/classApply',
    name: 'ClassApply',
    component: () => import('./page/classApply/classApply.vue'),
  },
  {
    path: '/review',
    name: 'Review',
    component: () => import('./page/review/Review.vue'),
  },

  {
    path: '/tutors',
    name: 'Tutors',
    component: () => import('./page/tutors/Tutors.vue'),
  },

  {
    path: '/topics',
    name: 'Topics',
    component: () => import('./page/topics/Topics.vue'),
  },

  {
    path: '/correction',
    name: 'Correction',
    component: () => import('./page/correction/Correction.vue'),
  },

  {
    path: '/b2b/introduce',
    name: 'B2BIntroduce',
    component: () => import('./page/b2b/Introduce.vue'),
  },
  // Footer
  {
    path: '/help',
    name: 'Help',
    component: () => import('./page/help/Help.vue'),
  },
  // 친구 추천
  {
    path: '/recommendEvent/:referral_code',
    redirect: '/',
    name: 'RecommendEvent',
    component: () => import('./page/recommendEvent/RecommendEvent.vue'),
  },
  {
    path: '/recommendEvent/done',
    name: 'RecommendEventJoinDone',
    component: () => import('./page/recommendEvent/RecommendJoinDone.vue'),
  },
  {
    path: '/promotions/chat',
    name: 'ChatPass',
    component: () => import('./page/promotion/ChatPass/index.vue'),
  },
  // {
  //   path: '/promotions/meetup',
  //   name: 'MeetUp',
  //   component: () => import('./page/promotion/MeetUp/index.vue'),
  // },
  // {
  //   path: '/plan/meetup/apply',
  //   name: 'MeetUpApply',
  //   component: () => import('./page/plan/meetUp/index.vue'),
  // },

  // B2B 프로그램
  {
    path: '/b2bProgram',
    name: 'B2BProgram',
    component: () => import('./page/b2b/Program.vue'),
  },
  {
    path: '/promotions/festival',
    name: 'Festival',
    component: () => import('./page/promotion/Pr365Low.vue'),
  },
];

export const router = createRouter({
  history: createWebHistory('/v2/'),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const realUrl = routes.map((route) => route.path);
  // 전체 등록된 페이지에 포함된 url이 아닌경우 (queryString으로 받아온 url도 포함됨)
  if (!realUrl.includes(to.path)) {
    // 종료된 프로모션 url인 경우
    if (to.path.includes('/promotions')) {
      // 웹뷰 or 딥링크로 켜진 케이스
      if (window.location.hostname.includes('app')) {
        // 웹뷰 닫기
        native.close();
      } else {
        // v2의 페이지인 경우는 v2 홈으로
        next('/');
      }
      // queryString으로 받아온 url의 경우는 조건에 도달하지않는다
    } else {
      next();
    }
  } else {
    next();
  }
});
